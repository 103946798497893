@import "./reset";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;900&family=Roboto:wght@100;400;700;900&display=swap");

$white: #eee;
$black: #222;
$green: #00ac72;
$red: #dd4545;

body,
html {
    background: $white;
    color: $black;
    font-family: "Roboto", sans-serif;
    line-height: 150%;
}

strong {
    font-weight: 900;
}

ul {
    list-style: none;
    padding: 0;
}

button {
    border: none;
    background: transparent;
    border: 2px solid $black;
    border-radius: 9999px;
    padding: 7px 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        background: $black;
        color: $white;
    }
}

section {
    position: relative;
    max-width: 95%;
    padding: 0 20px;
    background: lighten($white, 10%);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    transition: 0.3s ease-in-out;

    .section-header,
    .inactive {
        padding: 20px 0;
    }

    .section-header.display {
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            color: lighten($black, 50);
        }
    }
    .section-header.edit {
        h2 {
            input {
                width: 100px;
            }
        }
        input {
            outline: none;
            border: none;
            padding: 5px;
            border-radius: 9999px;
            border: 2px solid $black;
            text-align: center;
            transition: 0.3s ease-in-out;

            &:focus {
                border-color: $green;
            }
        }
        button {
            border: none;
            display: block;
            margin: 0 auto;
            width: 100px;
            text-align: center;

            &:hover {
                background: transparent;
                transform: scale(1.5);
                color: $green;
            }
        }
    }

    &.active {
        padding-bottom: 20px;
        .inactive {
            display: none;
        }

        .list,
        .section-header,
        .footer,
        .empty,
        .absolute-session-edit {
            display: block;
        }

        &:hover {
            transform: scale(1);
        }
    }

    &:hover {
        transform: scale(1.02);
    }
    .inactive {
        .inactive-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h2 span {
            display: inline-block;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;
        }
        p {
            text-align: center;
            width: 100%;
            font-size: 20px;
            margin: 0;

            span {
                width: 35px;
                height: 35px;
                padding: 5px;
                border-radius: 50%;
                display: inline-block;
                transition: 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                    background: $black;
                    color: $white;
                }
            }
        }
    }

    .list,
    .section-header,
    .footer,
    .empty,
    .absolute-session-edit {
        display: none;
    }

    .absolute-session-edit {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    h2,
    h3 {
        text-align: center;

        span {
            font-weight: 400;
        }
    }

    .list {
        margin: 0;

        .head,
        .building .display,
        .building .editing,
        .input-group {
            display: grid;
            grid-template-columns: 0.2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.3fr 0.3fr;
            justify-content: center;
            align-items: center;
            padding: 10px 0;

            &.head {
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 10px;
                padding: 10px;
                color: $white;
                background: $black;
            }

            div {
                text-align: center;
            }
        }

        .building {
            padding: 10px;

            .delete-estate button,
            .edit-estate button {
                border: none;

                &:hover {
                    background: transparent;
                    transform: scale(1.5);
                    color: $red;
                }
            }

            .edit-estate button:hover {
                color: $green;
            }

            &:nth-child(odd) {
                background: darken($white, 7);
            }

            input {
                text-align: center;
                width: 90%;
                padding: 5px;
                border: none;
                outline: none;
                background: transparent;
                border-bottom: 2px solid $black;
                transition: 0.3s ease-in-out;

                &:focus {
                    border-bottom: 2px solid $green;
                }
            }
            .buyAt.input {
                display: flex;
                justify-content: space-between;
                align-items: center;

                input {
                    width: 40%;
                    text-align: center;
                }
            }

            .display {
                transition: 0.3s ease-in-out;
                border-radius: 10px;
                cursor: pointer;
                &:hover {
                    background: lighten($green, 50);
                }
            }
        }
    }

    .footer {
        margin-top: 40px;
    }
}

.totalStats {
    background: $black;
    color: $white;
    width: 95%;
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    h2 {
        text-align: center;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 20px;
    }

    .list {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            .title {
                text-transform: uppercase;
                font-weight: 400;
            }

            &:nth-child(even) {
                background: lighten($black, 7);
            }
        }
    }
}

.input-group {
    padding: 10px;
    background: $black;
    color: $white;
    input,
    button {
        padding: 5px;
        color: $white;
        background: transparent;
        border: none;
        text-align: center;
        cursor: pointer;
        outline: none;
    }

    input {
        transition: 0.3s ease-in-out;
        &:focus {
            border-bottom: 2px solid $white;
        }
        border-bottom: 2px solid lighten($black, 15);
        width: 90%;
        margin: 0 auto;
    }

    .buyAt {
        input {
            width: 30%;
        }
    }
}

.new-session {
    width: 95%;
    margin: 10px auto;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;

    &,
    .input-group {
        background: $green;
    }
    .input-group {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .period {
            display: flex;

            input {
                width: 30%;
            }
        }
        input {
            width: 50%;
            border-bottom: 2px solid darken($green, 15);

            &::placeholder {
                color: darken($green, 15);
            }

            &:focus {
                border-bottom: 2px solid $white;
            }
        }
    }
    button {
        border-color: darken($green, 20);
        color: darken($green, 20);

        &:hover {
            background: darken($green, 20);
            color: $white;
        }
    }
}
